<h1 mat-dialog-title>{{ configuration?.Oid ? 'Modifica' : 'Creazione' }} Regola Smart Delivery </h1>

<mat-dialog-content>

  <form [formGroup]="form">

    <mat-form-field>

      <mat-label>Nome</mat-label>

      <input matInput formControlName="Name" />

      <mat-error *ngIf="form.get('Name')?.hasError('required')"> * Campo obbligatorio</mat-error>

    </mat-form-field>

    <mat-form-field>

      <mat-label>Descrizione</mat-label>

      <input matInput formControlName="Description" />

    </mat-form-field>

    <mat-form-field style="width: 48%; margin-right: 4%;">

      <mat-label>Codice</mat-label>

      <input matInput formControlName="ApiCode" />

      <mat-error *ngIf="form.get('ApiCode')?.hasError('required')"> * Campo obbligatorio</mat-error>

    </mat-form-field>

    <mat-form-field style="width: 48%;">

      <mat-label>Nome fisso allegato</mat-label>

      <input matInput formControlName="AttachmentRename" />

    </mat-form-field>

    <div style="padding-top: 10px; padding-bottom: 10px;">

      <section>
        <mat-checkbox class="checkbox-margin" formControlName="Enabled"> Attiva</mat-checkbox>
      </section>

    </div>

  </form>

  <hr />


  <div class="rules-container" [class.empty]="!rows.length">
    <div align="end" style="margin-bottom: 10px">

      <button mat-raised-button color="accent" (click)="addPriority()">
        Aggiungi priorità
      </button>

    </div>

    <ng-container *ngIf="rows.length; else emptyTemplate">

      <p *ngIf="rows.length > 1"> Puoi trascinare un elemento della lista per riordinare le priorità. </p>

      <div
        cdkDropList
        [cdkDropListData]="rows"
        (cdkDropListDropped)="onDrop($event)">

        <div
          *ngFor="let rule of rows; let index = index;"
          class="rule-row mat-elevation-z2"
          cdkDrag>

          <span style="overflow: hidden;white-space: nowrap;">

            <button (click)="delete(index)" mat-icon-button>
              <mat-icon color="warn">delete</mat-icon>
            </button>

            [<strong>{{ rule?.SendTypeConfiguration?.SendTypeName }}</strong>] {{ rule.Template?.Name ?? 'Modello' }}

          </span>

          <div style="display: flex; flex-direction: row; min-width: 80px">

            <button mat-icon-button *ngIf="rows.length > 1">
              <mat-icon color="primary">touch_app</mat-icon>
            </button>

            <button mat-icon-button (click)="ruleActivationConditions(rule)">
              <mat-icon color="accent">settings</mat-icon>
            </button>

          </div>

        </div>

      </div>

    </ng-container>

    <ng-template #emptyTemplate>

      <p>Al momento non è stata ancora definita alcuna priorità.</p>

    </ng-template>

  </div>

</mat-dialog-content>

<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="space-between" style="margin-bottom: -9px">

  <div>

    <button mat-raised-button color="warn" (click)="cancel()">
      Annulla
    </button>

  </div>

  <div>

    <button mat-raised-button color="primary" (click)="submit()">
      Salva
    </button>

  </div>

</div>
