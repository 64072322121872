import { FlowCommon, FlowCommonFilters } from "./flow-common.parameter";
import { CtSearchFilterControlConfiguration } from "@ctsolution/ct-framework";

const RecipientNameFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("RecipientName")
    .setLabel("Destinatario");

const TrackingCodeInternalCodeFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("TrackingCodeInternalCode")
    .setLabel("Barcode/Codice Interno completo");

const FlowDescriptionFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("FlowDescription")
    .setLabel("Descrizione ordine");

export class FlowRecipientListParameter extends FlowCommon {

  RecipientName?: string;
  SmartDeliveryOid?: any;

  constructor() {

    super();

  }

}

export const FlowRecipientFilters: CtSearchFilterControlConfiguration[] = [...FlowCommonFilters, RecipientNameFilter, TrackingCodeInternalCodeFilter, FlowDescriptionFilter];
