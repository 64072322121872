import {
  CtControlConfiguration, CtControlTypes,
  CtSearchFilterControlConfiguration,
  CtSelectControlOptions, CtSelectControlValue, CtThemeTypes,
  SEARCH_FILTER_CONTROL_TYPE
} from "@ctsolution/ct-framework";
import { DEFAULT_LABEL } from "../lib/company-ui/company.ui-customization.service";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { DataRequest, MethodEnum } from "@ctsolution/ct-webapi";
import { ISendTypeConfiguration } from "../interfaces/send-type-configuration";
import { distinctUntilChanged } from "rxjs/operators";

export class FlowCommon {

  CompanyCode?: string; // da esporre come filtro? mmh
  DateFrom?: Date;
  DateTo?: Date;
  GroupCode?: string;
  Product?: string;
  UserExternalIDMail?: string;
  FlowOid?: number;
  FlowDescription?: string;
  TrackingCodeInternalCode?: string;

  constructor() {
  }

}

export const CompanyCodeFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("CompanyCode")
    .setLabel(DEFAULT_LABEL.COMPANY_CODE);

export const GroupCodeFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("GroupCode")
    .setLabel(DEFAULT_LABEL.GROUP_CODE);

enum DateComparisonType {
  LESS_THAN,
  GREATER_THAN,
}

function setupDateFilterControl(primaryFilter: CtSearchFilterControlConfiguration, compareFilter: CtSearchFilterControlConfiguration, comparisonType: DateComparisonType) {

  primaryFilter
    .control
    .valueChanges
    .pipe(distinctUntilChanged())
    .subscribe(value => {

      const compareValue = compareFilter.control.value as Date;

      if (!compareValue) return;

      switch (comparisonType) {

        case DateComparisonType.LESS_THAN:
          if (compareValue.getTime() < value.getTime()) {
            compareFilter.control.setValue(null);
          }

          break;

        case DateComparisonType.GREATER_THAN:
          if (compareValue.getTime() > value.getTime()) {
            compareFilter.control.setValue(value);
          }
          break;
        default:
          break;
      }

    });

}

export const DateFromFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("DateFrom")
    .setType(SEARCH_FILTER_CONTROL_TYPE.DATE)
    .setLabel("Data inizio");

export const DateToFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("DateTo")
    .setType(SEARCH_FILTER_CONTROL_TYPE.DATE)
    .setLabel("Data fine");

setupDateFilterControl(DateFromFilter, DateToFilter, DateComparisonType.LESS_THAN);
setupDateFilterControl(DateToFilter, DateFromFilter, DateComparisonType.GREATER_THAN);

const UserExternalIDMailFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("UserExternalIDMail")
    .setLabel("Creato da")
    .setHint("E-mail, nominativo");

//#region PRODUCT FILTER

const productLookupOptions: CtSelectControlOptions = CtSelectControlOptions
  .create()
  .setLookupResponseMapper((response: CtWebapiGenericResponse<ISendTypeConfiguration[]>) => {

    return response
      .Result
      .map((elm: ISendTypeConfiguration) => {

        return CtSelectControlValue.create()
          .setLabel(elm.SendTypeName)
          .setValue(elm.SendTypeName);

      });

  })
  .setNullValueEnabled(true);

const ProductFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration
    .create("Product")
    .setLabel("Prodotto")
    .setCTControlConfiguration(
      CtControlConfiguration.create({
        name: "Product",
        label: "Prodotto",
        type: CtControlTypes.ENUMERABLE,
        theme: CtThemeTypes.MATERIAL,
        materialOptions: {
          appearance: "legacy"
        }
      } as CtControlConfiguration)
        .setOptions(productLookupOptions)
        .setRemoteValueOptionsEndpoint(
          DataRequest
            .create()
            .setController(["SendType"])
            .setAction("GetSendTypeConfigurationList")
            .setMethod(MethodEnum.GET)
            .setQueryParams({ hideSpinner: true })));

//#endregion PRODUCT FILTER


export const FlowCommonFilters: CtSearchFilterControlConfiguration[] = [DateFromFilter, DateToFilter, GroupCodeFilter, ProductFilter, UserExternalIDMailFilter];
