import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  MaxLengthValidator,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { AddressValidatorService } from "../../address-validator/address-validator.service";
import { AddressData } from "../../../../../core/classes/address-data";
import { FOREIGN_PROVINCE } from "../../../../../core/enums/destination.enum";
import { FlowSendAreaEnum } from "../../../../../core/enums/flow-send-area.enum";
import {
  localeZipCodeValidators,
  LocalizationService,
  provinceValidators,
  zipCodeValidators
} from "../../../../../core/lib/localization.service";
import { PERSON_SPECIFICATION_TYPE, PersonSpecificationsConfiguration } from "./person-specifications.configuration";
import { CompanyCustomConfiguration, ProtocolSettings, SendCategorySender } from "../../../../../core/interfaces/company-custom-configuration";
import { SendCategoryService } from "../../../../../send-category.service";
import { RecipientData } from "../../../../../core/interfaces/recipient-data";
import { GroupSender } from "../../../../../core/classes/group-sender";
import { ISuggestedAddressCorrection } from "../../../../../core/interfaces/address-validation";
import { SpecialCharsService } from "../../../../../core/lib/special-chars.service";
import { FormService } from "../../../../../core/lib/form.service";
import { PersonSpecificationsControls } from "../../../../../core/interfaces/person-specifications.controls";
import { JwtService } from "src/app/core/lib/jwt.service";

export const personSpecificationsDefaultValidators = () => [Validators.required, Validators.maxLength(100)];
const personSpecificationsEmailValidators = () => [Validators.email];
const personSpecificationsSmsSenderValidators = () => [Validators.minLength(4), Validators.maxLength(11)];
const emailUserNamePattern = /^[a-zA-Z0-9._+-]+$/;

@Component({
  selector: "app-person-specifications",
  templateUrl: "./person-specifications.component.html",
  styleUrls: ["./person-specifications.component.scss"],
  providers: [SpecialCharsService, LocalizationService]
})
export class PersonSpecificationsComponent implements OnInit {

  @Input() configuration!: PersonSpecificationsConfiguration;

  form: FormGroup;
  protocolSetting: ProtocolSettings | null = null;

  constructor(
    private _fb: FormBuilder,
    private _validator: AddressValidatorService,
    private sendCategoryService: SendCategoryService,
    private formService: FormService,
    public localizationService: LocalizationService,
    public jwtService : JwtService,
    public specialCharsService: SpecialCharsService) {

    this.form = this._fb.group({
      Name: new FormControl(null, personSpecificationsDefaultValidators()),
      Address: new FormControl(null, personSpecificationsDefaultValidators()),
      ZipCode: new FormControl(null, localeZipCodeValidators()),
      City: new FormControl(null, personSpecificationsDefaultValidators()),
      Province: new FormControl(null, provinceValidators()),
      Email: new FormControl(null, personSpecificationsEmailValidators()),
      Phone: new FormControl(null),
      Country: new FormControl(null),
      Protocol: new FormControl(null, Validators.maxLength(100))
    });

    this.form
      .valueChanges
      .subscribe((formValue) => {

        let zipCode = formValue.ZipCode;
        let city = formValue.City;

        // Verifica se il campo "City" contiene esattamente 5 cifre numeriche
        if (/^\d{5}$/.test(city)) {

          this.form.patchValue({ ZipCode: city, City: null }, { emitEvent: false });

        }

        // Verifica se il campo "ZipCode" contiene spazi vuoti e se lo zipcode è italiano
        if (zipCode && /\s/.test(zipCode) && this.configuration.localAreaConfiguration) {

          this.form.patchValue({ ZipCode: zipCode.replace(/\s/g, "") }, { emitEvent: false });

        }

        this.configuration
          .setVerified(null);

      });

  }

  async ngOnInit() {

    await this.specialCharsService.loadConfig();
    await this.setProtocol();

    await this.localizationService
      .loadConfig(<PersonSpecificationsControls>{
        ZipCodeControl: this.form.get("ZipCode"),
        CityControl: this.form.get("City"),
        ProvinceControl: this.form.get("Province"),
        CountriesControl: this.form.get("Country")
      });

  }

  get emailControlEnabled(): boolean {

    return (
      (this.configuration.flow?.emailStandaloneChannelSetupEnabled ?? false)
      ||
      ((this.configuration.flow?.OTPLetterStandaloneChannelSetupEnabled ?? false) && this.configuration.senderContainer) // se sono canale digitale Raccomandata OTP e sono nel form mittente
    );

  }

  get phoneControlEnabled(): boolean {

    return (
      ((this.configuration.flow?.smsStandaloneChannelSetupEnabled ?? false) && this.configuration.recipientContainer) // se sono canale digitale sms e sono nel form destinataro
      ||
      (this.configuration.flow?.OTPLetterStandaloneChannelSetupEnabled ?? false)); // se sono canale digitale Raccomandata OTP

  }

   async setProtocol() {

    const companyCustomConfiguration : CompanyCustomConfiguration | null = await this.jwtService.getCompanyCustomConfiguration();

    if(companyCustomConfiguration == null || companyCustomConfiguration.ProtocolSettings == null) return;

    if(this.configuration.personSpecificationType == PERSON_SPECIFICATION_TYPE.RECIPIENT)
    {

      this.protocolSetting = companyCustomConfiguration.ProtocolSettings;

    }

  }

  updateCurrentFlowValidators() {

    if (this.configuration.flow?.digitalSendingStandaloneChannelSetupEnabled) {

      // canali digitali non ho bisogno dei dati di indirizzo
      ["Address", "ZipCode", "City", "Province"].forEach(key => this.formService.clearControlValidators(this.form.get(key)));

    } else {

      let addressValidator: ValidatorFn[] = personSpecificationsDefaultValidators();

      // per tnotice la lunghezza dell'indirizzo è 30 caratteri
      if (this.configuration.flow?.OTPLetterStandaloneChannelSetupEnabled) {

        addressValidator = [Validators.required, Validators.maxLength(30)];

      }

      this.form.get("Address")?.setValidators(addressValidator);
      this.form.get("ZipCode")?.setValidators(localeZipCodeValidators());
      this.form.get("City")?.setValidators(personSpecificationsDefaultValidators());
      this.form.get("Province")?.setValidators(provinceValidators());

    }

    if(this.protocolSetting && this.protocolSetting.Mandatory == true)
    {

      this.setupProtocolValidator();

    }

    this.setupEmailValidators();

    this.setupPhoneValidators();

    this.setupNameValidators();

    this.form.updateValueAndValidity();

  }

  setNamePatternConfiguration(senderPrefix: string) {

    const regex = `^${senderPrefix}.{0,44}$`;

    const PATTERN_VALIDATOR = Validators.pattern(new RegExp(regex));
    const LENGTH_VALIDATOR: ValidatorFn = Validators.maxLength(senderPrefix.length + 44);

    this.configuration.setErrors({ pattern: `Attenzione: ricordati che il mittente deve sempre iniziare con "${senderPrefix}" seguito da un testo libero di massimo 44 caratteri.` });

    this.setupNameValidators([PATTERN_VALIDATOR, LENGTH_VALIDATOR]);

  }

  setNameDigitalConfiguration(sendCategorySenders: SendCategorySender[]) {

    const form = this.form;
    const sender: SendCategorySender | null = this.sendCategoryService.getCategorySenderByFlow(this.configuration.flow, sendCategorySenders);

    if (sender) {

      if (sender.Sender) {

        form.get("Name")?.setValue(sender.Sender);
        if (sender.Readonly) form.get("Name")?.disable();

      }

      if (sender.Phone) {

        form.get("Phone")?.setValue(sender.Phone);
        if (sender.Readonly) form.get("Phone")?.disable();

      }

      if (sender.Email) {

        const emailControl = form.get("Email");

        if (sender.Readonly) {

          emailControl?.setValue(sender.Email);
          emailControl?.disable();

        } else {

          const [username, provider] = sender.Email.split("@");

          if (username && !emailControl?.value) emailControl?.setValue(username);

          if (provider) this.configuration.setEmailProvider(`@${provider}`);

        }

        this.setupEmailValidators();

      }

    } else {

      form.get("Name")?.enable();
      form.get("Phone")?.enable();
      form.get("Email")?.enable();

    }

    form.updateValueAndValidity();

  }

  private setupNameValidators(additionalValidators: ValidatorFn[] = []) {

    const nameControl = this.form.get("Name");

    let NAME_VALIDATORS = personSpecificationsDefaultValidators();

    // se sono mittente di un sms
    if (this.configuration.flow?.smsStandaloneChannelSetupEnabled && this.configuration.senderContainer) {

      NAME_VALIDATORS = NAME_VALIDATORS.concat(...personSpecificationsSmsSenderValidators());

    }

    if (additionalValidators.length) {

      NAME_VALIDATORS = NAME_VALIDATORS.concat(...additionalValidators);

    }

    nameControl?.setValidators(NAME_VALIDATORS);

  }

  private setupEmailValidators() {

    const emailControl = this.form.get("Email");

    if (this.configuration.emailProvider) { // non devo più verificare se equivale ad una email, al massimo verificare che non ci siano campi speciali

      emailControl
        ?.setValidators([Validators.pattern(emailUserNamePattern), Validators.required]);

    } else {

      const emailValidators = personSpecificationsEmailValidators();

      if (this.emailControlEnabled) {

        emailValidators
          .push(Validators.required);

      }

      emailControl
        ?.setValidators(emailValidators);

    }

    emailControl?.updateValueAndValidity();

  }

  private setupProtocolValidator() {

    const protocolControl = this.form.get("Protocol");

    const protocolValidators = personSpecificationsDefaultValidators();

    protocolControl
      ?.setValidators(protocolValidators);

    protocolControl?.updateValueAndValidity();

    this.form.updateValueAndValidity(); //forzo il refresh perché senno non prende i validator su spedizioni esistenti


  }

  private setupPhoneValidators() {

    const phoneControl = this.form.get("Phone");

    const phoneValidators = [];

    if (this.phoneControlEnabled) {

      phoneValidators
        .push(Validators.required);

    }

    phoneControl?.setValidators(phoneValidators);

    phoneControl?.updateValueAndValidity();

  }

  /**
   * It opens a dialog with a form that validates the data in the form
   */
  validate() {

    this._validator
      .openValidator(this.configuration.title ?? "User Validation", <AddressData>this.form.value)
      .afterClosed()
      .subscribe((result: AddressData | null) => {

        if (result) {
          this.setFormValue(result);
        }

      });

  }

  /* A function that sets the form values. */
  setFormValue(address: RecipientData | GroupSender | AddressData | ISuggestedAddressCorrection) {

    address.Name = address.Name ?? this.form.get("Name")?.value; // serve a tenere il nome; il validatore quando normalizza l'indirizzo mi restituisce null per il nome

    if (!!this.configuration.emailProvider && (address.Email ?? "").indexOf("@") >= 0) {

      const [username] = (address.Email ?? "").split("@");

      if (username) address.Email = username;

    }

    this.form.patchValue(address);

    this.form.updateValueAndValidity();

    this.configuration
      .setVerified(this.form.valid); // arrivato a questo punto sto settando i valori dal validatore o da una get, sono dunque validi

  };

  /**
   * If the user has selected a foreign country, then add the zip code validators and set the province to "Foreign"
   */
  private handleForeignAddressValidations() {

    this.form.get("Country")?.setValidators([this.localizationService.autocompleteStringValidator(this.localizationService.countries ?? []), ...personSpecificationsDefaultValidators()]);
    this.form.get("ZipCode")?.setValidators(zipCodeValidators());
    this.form.get("Province")?.setValue(FOREIGN_PROVINCE);

    this.form.updateValueAndValidity();

  }

  /**
   * If the country is not Italy, then the zip code is validated as a string of at least 5 characters, the province is set
   * to null, and the country is removed from the form
   */
  private resetLocalAddressValidations() {

    this.form.get("ZipCode")?.setValidators(localeZipCodeValidators());
    this.form.get("Province")?.setValue(null);

    this.form.get("Country")?.setValidators(null);
    this.form.get("Country")?.setValue(null);

    this.form.updateValueAndValidity();

  }

  toggleDestinationValidation(sendArea: FlowSendAreaEnum) {

    this.configuration
      .setSendArea(sendArea);

    if (this.configuration.externalAreaConfiguration) {

      this.handleForeignAddressValidations();

    } else {

      this.resetLocalAddressValidations();

    }

  }

  //#endregion

}
