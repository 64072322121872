import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  SmartDeliveryConfigurationRow,
  SmartDeliveryConfigurationSaveParameter
} from "../../../../core/classes/smart-delivery-configuration-save.parameter";
import { SmartDeliveryRuleService } from "./smart-delivery-rule.service";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import {
  SmartDeliveryConfigurationController
} from "../../../../core/controllers/smart-delivery-configuration.controller";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { SnackbarService } from "../../../../core/lib/snackbar.service";
import {
  SmartDeliveryChangeConfirmComponent
} from "./smart-delivery-change-confirm/smart-delivery-change-confirm.component";

@Component({
  selector: "app-smart-delivery-detail",
  templateUrl: "./smart-delivery-detail.component.html",
  styleUrls: ["./smart-delivery-detail.component.scss"],
  providers: [SmartDeliveryRuleService, SmartDeliveryConfigurationController]
})
export class SmartDeliveryDetailComponent {

  form: FormGroup;
  parameter: SmartDeliveryConfigurationSaveParameter | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private smartDeliveryPriorityRuleService: SmartDeliveryRuleService,
    private dialogRef: MatLegacyDialogRef<SmartDeliveryDetailComponent>,
    private snackbar: SnackbarService,
    private smartDeliveryConfigurationController: SmartDeliveryConfigurationController,
    private dialog: MatLegacyDialog,
    @Inject(MAT_LEGACY_DIALOG_DATA) public configuration: SmartDeliveryConfigurationSaveParameter | null) {

    this.form = this.formBuilder
      .group({
        ApiCode: new FormControl(null, Validators.required),
        Name: new FormControl(null, Validators.required),
        Description: new FormControl(null),
        AttachmentRename : new FormControl(null),
        Enabled: new FormControl(false)
      });

  }

  get rows() {

    return this.parameter?.Rows ?? [];

  }

  ngOnInit() {

    this.parameter = this.configuration ?? SmartDeliveryConfigurationSaveParameter.create();

    (this.parameter?.Rows ?? []).sort((a, b) => (a.Priority ?? -1) - (b.Priority ?? -1));

    this.form.patchValue(this.parameter);

  }

  addPriority() {

    this.smartDeliveryPriorityRuleService
      .openRulePriorityForm(this.parameter)
      .afterClosed()
      .subscribe(result => {

        if (result) {

          result
            .setPriority((this.parameter?.Rows ?? []).length + 1);

          this.parameter?.Rows.push(result);

        }

      });

  }

  ruleActivationConditions(rule: SmartDeliveryConfigurationRow) {

    this.smartDeliveryPriorityRuleService
      .openRuleActivationConditions(rule)
      .afterClosed()
      .subscribe((response) => {

        if (response) {

          this.snackbar.open("Condizioni aggiornate correttamente!", "X");

        }

      });

  }

  delete(index: number) {

    this.parameter?.Rows.splice(index, 1);

  }

  onDrop(event: CdkDragDrop<SmartDeliveryConfigurationRow[]>) {

    if (event.previousContainer === event.container) {

      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    } else {

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

    }

    this.recalculateRulePriorities();

  }

  private recalculateRulePriorities() {

    this.parameter
      ?.Rows
      .forEach((rule, index) => rule.setPriority(index + 1));

  }

  cancel() {

    this.dialogRef
      .close();

  }

  async submit() {

    if (this.form.valid) {

      const parameter: SmartDeliveryConfigurationSaveParameter = SmartDeliveryConfigurationSaveParameter
        .create(this.form.value)
        .setRows(this.parameter?.Rows ?? []);

      if (this.parameter?.Oid) {

        parameter
          .setOid(this.parameter.Oid);

        const confirm = await this.dialog.open(SmartDeliveryChangeConfirmComponent, { maxWidth: "500px" }).afterClosed().toPromise();

        if (!confirm) return;

      }

      this.smartDeliveryConfigurationController
        .save(parameter)
        .subscribe((response: CtWebapiGenericResponse<number> | null) => {

          this.snackbar.open("Regola salvata correttamente!", "X");

          this.dialogRef
            .close(response?.Result);

        });

    }

  }

}
